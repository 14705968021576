.wrapper {
  display: flex;
  flex-direction: row;
  gap: var(--size-xsmall);
  background: var(--color-foreground-xxlight);
  padding: 1px 9px 1px 1px;
  border-radius: 999em;
  align-items: center;
  color: var(--color-foreground-medium);
}

.wrapper svg path {
  fill: currentColor;
}

.chevron {
  display: inline-flex;
}

.chevron svg {
  display: inline-flex;
  width: 12px;
  height: 12px;
}

@media only screen and (min-width: 992px) {
  .wrapper.clickable {
    cursor: pointer;
    transition: opacity var(--anim-ease-out) 300ms;
  }

  .wrapper.clickable:hover {
    opacity: var(--hover-opacity);
    transition-duration: 50ms;
  }
}
