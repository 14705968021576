.container {
  overflow: hidden;
  display: flex;
  margin-left: calc(-1 * var(--size-medium));
  margin-right: calc(-1 * var(--size-medium));
  padding-left: var(--size-medium);
  padding-right: var(--size-medium);
  margin-top: calc(-1 * var(--size-medium));
}

.step_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  flex-shrink: 0;
  gap: 24px;
}

.brand {
  text-align: center;
  background-color: var(--color-foreground-xxlight);
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: calc(-1 * var(--size-medium));
  margin-right: calc(-1 * var(--size-medium));
}

.step_content {
  display: flex;
  align-items: center;
}

.step_content p {
  line-height: 1px;
}

.step_number {
  background-color: var(--color-accent);
  border-radius: 6px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--size-small);
  color: var(--color-background);
}

.backButton {
  width: var(--min-touch-size);
  height: var(--min-touch-size);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 6px;
  background-color: var(--color-foreground-xlight);
}

.backButton svg {
  display: inline-flex;
  transform: rotate(180deg);
}

.backButton svg path {
  fill: var(--color-foreground-medium);
}

.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--size-medium);
}

.nav_dashes {
  display: inline-flex;
  position: relative;
  background: var(--color-foreground-xlight);
}

.navDash {
  display: inline-flex;
  position: relative;
  z-index: 2;
  border-right: var(--size-small) solid var(--color-background);
}

.navDash svg {
  display: inline-flex;
}

.nav_dashes > .navDash:last-child {
  border-right: none;
}

.navLine {
  position: absolute;
  height: 100%;
  background: var(--color-foreground-medium);
  width: 25%;
  z-index: 1;
  border-radius: 999em;
  transition: transform cubic-bezier(0.17, 0.84, 0.44, 1) 300ms;
}

.navDash svg path {
  fill: var(--color-background);
}

.next_button {
  text-align: center;
  padding: 16px 0;
  width: 100%;
}

.done {
  background: var(--color-accent-xlight);
  border-radius: 6px;
  width: 76px;
  margin-left: auto;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 8px;
  color: var(--color-accent);
  font-weight: var(--font-weight-bold);
}

.tooltip_mask {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(var(--color-background-rgb), 0.2);
  display: flex;
  opacity: 0;
  transform: scale(1.01) translateY(-10px);
  transition: all var(--anim-ease-out) 450ms;
  transform-origin: bottom center;
}

.tooltip_mask.isVisible {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.tooltip_container {
  z-index: 102;
  width: 220px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tooltipBody {
  background-color: var(--color-foreground);
  padding: var(--size-medium);
  border-radius: var(--border-radius-normal);
  color: var(--color-background);
  display: flex;
  flex-direction: column;
  gap: var(--size-medium);
  text-align: center;
}

.isVisible .tooltipBody,
.isVisible .tooltipStem {
  opacity: 1;
  transform: translateY(0);
}

.tooltipBody {
  --color-button-foreground: var(--color-foreground);
  --color-button-background: var(--color-background);
}

.tooltip_container button {
  width: 100%;
  color: var(--color-foreground);
}

.tooltipStem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltip_line {
  background-color: var(--color-foreground);
  width: 2px;
  height: 32px;
  z-index: 101;
}

.tooltip_dot {
  background-color: var(--color-foreground);
  border-radius: 10px;
  width: 10px;
  height: 10px;
  z-index: 102;
}

.right.tooltip_container {
  align-items: flex-end;
}

.right .tooltipStem {
  margin-right: 8px;
}

@media only screen and (min-width: 992px) {
  .backButton {
    cursor: pointer;
  }

  .backButton:hover {
    opacity: 0.8;
  }
}
