.AddressSearch {
}

.AddressSearch_input {
  border-radius: var(--border-radius-normal);
  height: 44px;
  border: 0;
  background-color: var(--color-foreground-xxlight);
  box-shadow: none !important;
  flex-shrink: 0;
}

.AddressSearch_input svg {
  width: 24px;
  height: 24px;
}

.AddressSearch_input svg path {
  fill: var(--color-foreground-medium);
}

.AddressSearch_input input {
  color: var(--color-foreground);
}

.AddressSearch_input input::placeholder {
  color: var(--color-foreground-light);
}

.AddressSearch_clear {
  background-color: var(--color-foreground-light);
}

.AddressSearch_clear svg path {
  fill: var(--color-background);
}

.AddressSearch_resultsScroll {
  overflow-y: auto;
  flex: 1;
  margin-top: var(--size-medium);
}

.AddressSearch_resultsList {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.AddressSearch_resultsTitle {
  color: var(--color-foreground-medium);
  font-size: var(--font-size-body);
  font-weight: 700;
  padding: var(--size-small) 0;
}
