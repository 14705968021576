.Code {
  display: grid;
  grid-template-rows: 1fr min-content 1fr;
  justify-content: center;
  grid-area: body;
  gap: var(--size-large);
}

.Code_header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--size-small);
}

.Code_header p {
  font-size: var(--font-size-xlarge);
}

.Code_balance {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: opacity ease-out 300ms;
}

.Code_balance.is-empty {
  opacity: 0.5;
  filter: grayscale(1);
}

.Code_balance h2 {
  margin: 0;
  padding: 0;
  color: var(--color-accent);
  font-size: var(--font-size-amount-entry);
  font-weight: var(--font-weight-bold);
  line-height: 1;
}

.Code_balanceIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-accent);
  border-radius: 50%;
  position: relative;
  bottom: 0;
}

.Code_balanceIcon svg {
  width: 18px;
  height: 18px;
  display: inline-flex;
}

.Code_balanceIcon svg path {
  fill: var(--color-lightContent);
}

.Code_messageContainer {
  margin-top: 8px;
  color: var(--color-foreground-medium);
  font-size: var(--font-size-small);
  text-align: center;
  display: flex;
}

.Code_messageContainer > span {
  display: flex;
  gap: var(--size-xsmall);
}

.Code_footer {
  text-align: center;
}

.Code_footer p {
  max-width: 300px;
  font-size: var(--font-size-normal);
  color: var(--color-foreground-medium);
  margin: 0 auto;
}

.Code_qr {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
  position: relative;
}

.Code_qrInner {
}

@media only screen and (min-width: 992px) {
  .Code_balanceIcon {
    width: 36px;
    height: 36px;
    bottom: 5px;
  }

  .Code_balanceIcon svg {
    width: 24px;
    height: 24px;
  }

  .Code_balance {
    margin-bottom: -8px;
  }
}
