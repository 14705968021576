.Button {
  display: flex;
  flex-direction: row;
  gap: var(--size-small);
  appearance: none;
  background-color: var(--color-button-background);
  border: 0;
  border-radius: var(--border-radius-normal);
  color: var(--color-button-foreground) !important;
  font-size: var(--font-size-body);
  align-items: center;
  justify-content: center;
  height: 46px;
  padding: 0 var(--size-medium);
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  font-weight: var(--font-weight-bold);
  transition: all ease-out 300ms;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
}

.Button-secondary {
  position: relative;

  --color-button-foreground: var(--color-foreground);
  --color-button-background: var(--color-background);
}

.Button-secondary::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  border: 1px solid var(--color-button-foreground);
  border-radius: inherit;
  opacity: 0.07;
}

.Button-invisible {
  background-color: transparent;
  border: none;
  box-shadow: none !important;
  margin-left: calc(-1 * var(--size-medium));
  margin-right: calc(-1 * var(--size-medium));

  &.Button-small {
    margin-left: -10px;
    margin-right: -10px;
  }

  &.Button-xsmall {
    margin-left: calc(-6px);
    margin-right: calc(-6px);
  }
}

.Button-invisible::before {
  display: none;
}

.Button_text {
  display: flex;
  flex-direction: column;
  line-height: 1;
}

.Button:active,
.LinkButton:active {
  opacity: var(--pressed-opacity) !important;
  transition-duration: 50ms;
}

.Button:active {
  transform: translateY(1px);
}

.Button.is-disabled {
  pointer-events: none;
  box-shadow: none !important;

  --color-button-foreground: var(--color-foreground-medium);
  --color-button-background: var(--color-foreground-xlight);
}

.Button.Button.is-disabled > * {
  opacity: var(--disabled-opacity);
}

.Button-small,
.Button-xsmall {
  display: inline-flex;
  width: auto;
  height: 32px;
  font-size: var(--font-size-small);
  line-height: 12px;
  padding: 0 10px;
  gap: 4px;
}

.Button-xsmall {
  height: 24px;
  font-size: var(--font-size-xsmall);
  line-height: 10px;
  padding: 0 6px;
  gap: 2px;
}

.Button_icon {
  display: inline-flex;
  position: relative;
  top: -1px;
}

.Button_icon svg {
  width: 24px;
  height: 24px;
  display: inline-flex;
}

.Button-small .Button_icon svg {
  width: 16px;
  height: 16px;
}

.Button-xsmall .Button_icon svg {
  width: 13px;
  height: 13px;
}

.Button_icon svg:not(.avoid-fill) path {
  fill: var(--color-button-foreground);
}

.Button_loader {
  width: 24px;
  height: 24px;
  display: inline-flex;
  position: relative;
  color: var(--color-button-foreground) !important;
}

.LinkButton {
  appearance: none;
  border: none;
  background: transparent;
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-bold);
  color: inherit !important;
  padding: 0 2px;
  transition: opacity ease-out 300ms;
  position: relative;
  text-decoration: none;
  user-select: none;
}

.LinkButton::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  content: "";
  background-color: currentColor;
  opacity: 0.1;
}

.LinkButton-small {
  font-size: var(--font-size-small);
}

.Button.color-warning {
  background: var(--color-warning);
}

.Button.color-light {
  font-weight: 700;
  color: var(--color-foreground-medium);
}

@media (prefers-color-scheme: dark) {
  [data-theme="dark"] {
    .Button {
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    }

    .Button.is-disabled {
      color: var(--color-lightContent-light);
    }

    .Button-secondary {
      --color-button-background: var(--color-background);
    }

    .Button-secondary::before {
      opacity: 0.1;
    }

    .LinkButton::after {
      opacity: 0.3;
    }
  }
}

@media only screen and (min-width: 992px) {
  .Button,
  .LinkButton {
    cursor: pointer;
  }

  .Button:hover,
  .LinkButton:hover {
    opacity: var(--hover-opacity);
    transition-duration: 50ms !important;
  }

  .Button-invisible:hover {
    background-color: var(--color-foreground-xlight);
  }
}
