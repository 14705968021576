.card a {
  color: var(--color-foreground);
  font-weight: var(--font-weight-bold);
  text-decoration-color: var(--color-foreground-light);
  transition: opacity ease 150ms;
}

.cardTitleIcon {
  display: inline-flex;
  opacity: 0.5;
}

.cardTitleIcon svg {
  width: 24px;
  height: 24px;
}

.cardTitleIcon svg path {
  fill: var(--color-foreground);
}

.aboutCard a:hover {
  opacity: var(--hover-opacity);
}

.start {
  display: flex;
  flex-direction: column;
  gap: var(--size-large);
}

.startItem {
  display: flex;
  gap: var(--size-small);
  text-decoration: none;
  color: var(--color-foreground);
  transition: opacity ease-out 300ms;
}

.startItem:active {
  opacity: 0.5;
  transition-duration: 50ms;
}

.startItemNumber {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-accent);
  flex-shrink: 0;
  border-radius: 50%;
  color: var(--color-background);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
}

.startItemComplete .startItemNumber {
  opacity: 0.5;
}

.startItemDescription {
  display: flex;
  flex-direction: column;
  gap: var(--size-small);
  align-items: flex-start;
  width: 100%;
}

.startItemDescription a {
  color: inherit;
}

.startTitle {
  display: flex;
  align-items: center;
  gap: var(--size-small);
  width: 100%;
}

.startTitle.isComplete p {
  color: var(--color-foreground-medium) !important;
  text-decoration: line-through;
}

.startTitle a,
.startTitle p {
  height: 24px;
  display: flex;
  align-items: center;
  color: var(--color-accent) !important;
}

.startItemIcon {
  width: 24px;
  height: 24px;
  align-self: center;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: var(--size-small);
}

.startItemComplete .startItemIcon {
  background-color: var(--color-accent-xlight);
}

.startItemComplete .startItemIcon svg path {
  fill: var(--color-accent);
}

.startItemIcon svg {
  width: 18px;
  height: 18px;
}

.startItemIcon svg path {
  fill: var(--color-foreground-medium);
}

.startOptionalLabel {
  padding: 3px 8px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.5px;
  border: 1px solid var(--color-foreground-xlight);
  border-radius: 999em;
  color: var(--color-foreground-medium);
}

.faq {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-top: calc(-1 * var(--size-medium));
  margin-bottom: calc(-1 * var(--size-medium));
}

.faqTitle {
  display: flex;
  align-items: center;
  gap: var(--size-small);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-normal);
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  min-height: var(--row-min-height);
  cursor: pointer;
  transition: opacity ease 150ms;
}

.faqTitle.hasBorder {
  border-top: 1px solid var(--color-foreground-xlight);
}

.faqTitle svg {
  flex-shrink: 0;
}

.faqToggle {
  transition: transform var(--anim-ease-out) 150ms;
  flex-shrink: 0;
}

.faqBody {
  padding-bottom: var(--size-medium);
}

@media only screen and (min-width: 992px) {
  .faqTitle:hover {
    opacity: var(--hover-opacity);
    transition-duration: 50ms;
  }
}
