.BottomSheet_wrapper {
  height: auto !important;
  background-color: var(--color-background) !important;
}

.BottomSheet {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  height: 100%;
  position: relative;
}

.BottomSheet_icon {
  padding: var(--size-medium) var(--size-medium) 0 var(--size-medium);
}

.BottomSheet_header {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 var(--size-medium);
}

.BottomSheet_header--line {
  border-bottom: 1px solid var(--color-foreground-xlight);
}

.BottomSheet_header--minHeight {
  min-height: 60px;
}

.BottomSheet_content {
  padding: var(--size-medium);
  overflow-y: auto;
  flex: 1;
  overflow-x: hidden;
  line-height: var(--line-height-normal);
}

.BottomSheet_root .ant-drawer-content-wrapper {
  display: flex;
}

.BottomSheet_root .ant-drawer-content-wrapper-hidden {
  display: none !important;
}

.BottomSheet-noPadding .BottomSheet_content {
  padding: 0;
}

.BottomSheet_content::-webkit-scrollbar {
  width: 6px;
}

.BottomSheet_content::-webkit-scrollbar-thumb {
  background: var(--color-foreground-light);
  border-radius: 999em;
  border: 1px solid var(--color-background);
}

.BottomSheet_content::-webkit-scrollbar-track {
  background-color: transparent;
}

.BottomSheet_footer {
  padding: var(--size-medium);
  border-top: 1px solid var(--color-foreground-xlight);
}

.BottomSheet_titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 12px;
}

.BottomSheet_titleWrapper h3 {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  margin: 0;
  color: var(--color-foreground);
}

.BottomSheet_titleWrapper label {
  font-size: var(--font-size-small);
  color: var(--color-foreground-medium);
}

.BottomSheet_close {
  width: var(--min-touch-size);
  height: var(--min-touch-size);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 6px;
  background-color: var(--color-foreground-xlight);
  position: absolute;
  right: var(--size-medium);
  top: calc(30px - (var(--min-touch-size) / 2));
  z-index: 1000;
}

.BottomSheet_headerNavLeading {
  top: calc(50% - (var(--min-touch-size) / 2));
  left: var(--size-medium);
  position: absolute;
}

.BottomSheet_close:active {
  opacity: 0.3 !important;
}

.BottomSheet_close svg {
  width: 24px;
  height: 24px;
  display: inline-flex;
}

.BottomSheet_close svg path {
  fill: var(--color-foreground-medium);
}

.BottomSheet-Overlay {
  max-height: 80vh;
  overflow: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  top: unset;
}

.BottomSheet-Overlay-content {
  background: var(--color-background) !important;
  color: var(--color-foreground) !important;
  padding: 0 !important;
  display: flex !important;
  min-height: 0;
}

.BottomSheet-Overlay-body {
  width: 100%;
}

.BottomSheet-Overlay-root .BottomSheet-Overlay-wrap {
  display: flex;
  align-items: center;
}

.BottomSheet-Overlay .BottomSheet_footer {
  position: relative;
}

@media only screen and (min-width: 992px) {
  .BottomSheet_close {
    cursor: pointer;
  }

  .BottomSheet_close:hover {
    opacity: 0.8;
  }
}
