.link {
  color: var(--color-accent);
  font-weight: var(--font-weight-bold);
}

.chevron svg path {
  fill: var(--color-foreground-medium);
}

.chevron {
  transform: rotate(90deg);
}
