.wrapper {
  flex: 1;
  display: grid;
  grid-template-areas: "icon";
  overflow: hidden;
  align-self: stretch;
  justify-self: stretch;
}

.wrapper > * {
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
}

.base svg path,
.op svg path {
  fill: var(--color-accent);
}
