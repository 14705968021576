.set_password_container {
  text-align: center;

  p {
    margin-bottom: var(--size-medium);
  }
}

.learn_more {
  margin-bottom: var(--size-medium);
}

.password_input {
  margin: 24px 0 12px;
  display: flex;
  flex-direction: column;
  gap: var(--size-medium);
}

.beamBrand svg path {
  fill: var(--color-foreground);
}
