.wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - var(--navbar-height) - var(--bottom-bar-height) - var(--size-medium));
  align-items: center;
  justify-content: center;
  gap: var(--size-large);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--size-medium);
  text-align: center;
  padding: var(--size-medium);
}

.amountInner {
  display: flex;
  align-items: baseline;
  gap: var(--size-small);
  justify-content: center;
}

.amountWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.amount {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-amount-entry);
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-foreground);
  border-radius: 50%;
  position: relative;
  bottom: 1px;
}

.icon svg {
  width: 20px;
  height: 20px;
  display: inline-flex;
}

.icon svg path {
  fill: var(--color-background);
}

@media only screen and (min-width: 992px) {
  .icon {
    width: 36px;
    height: 36px;
    bottom: 5px;
  }
}
