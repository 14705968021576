.input_container {
  width: 100%;

  display: flex;
  justify-content: space-between;
}

.input_digit {
  width: 40px;
  margin: 0 4px;
  border-radius: var(--border-radius-normal);
  text-align: center;
  font-family: var(--font-stack-monospace);
  font-size: var(--font-size-large);
  font-style: normal;
  font-weight: 700;
  outline: none;
  border-color: var(--color-foreground-medium);
}

.input_digit:focus {
  border-color: var(--color-foreground);
}
