.Home {
  height: 100%;
  display: grid;
  justify-content: stretch;
  grid-template-rows: var(--navbar-height) auto var(--bottom-bar-height);
  grid-template-areas:
    "navbar"
    "body"
    "bottombar";
}
