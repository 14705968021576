.LoadAccount-ButtonContainer {
  width: 100%;
  border-top: 1px solid var(--color-foreground-xlight);
  padding-top: var(--size-medium);
}

.LoadAccount-Button__SMS {
  background: var(--color-green);
}

.LoadAccount-Button__X {
  background: #000000;
  color: #ffffff;
}

.LoadAccount-Button__X svg path {
  fill: #ffffff;
}

.LoadAccount__warning-card {
  color: var(--color-warning);
}

@media only screen and (min-width: 992px) {
  .LoadAccount-ButtonContainer {
    flex-direction: row;
  }

  .LoadAccount-ButtonContainer > * {
    flex: 1;
  }
}
