#coinbase-widget {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.coinbase-info {
  display: flex;
  flex-direction: column;
  gap: var(--size-large);
  padding: var(--size-medium);
  border-radius: var(--border-radius-normal);
  max-width: 400px;
  align-items: center;
  justify-content: center;
}

.coinbase-button {
  display: flex;
  flex-direction: column;
  gap: var(--size-small);
}

.coinbase-button p {
  opacity: 0.5;
}

.coinbase-loader {
  display: flex;
  gap: var(--size-medium);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
}

.coinbase-divider {
  border-bottom: 1px solid var(--color-foreground-xlight);
  width: 100%;
}
