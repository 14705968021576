.Card {
  background-color: var(--color-background);
  border-radius: var(--border-radius-normal);
  padding: var(--size-medium);
  color: var(--color-foreground);
  font-size: var(--font-size-body);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid var(--color-foreground-xlight);
  position: relative;
}

.Card_content {
  position: relative;
  z-index: 2;
}

.Card-muted {
  background-color: var(--color-foreground-xxlight);
  box-shadow: none;
  border: 0;
}

.Card_titleContainer {
  padding: var(--size-medium) 0;
  border-bottom: 1px solid var(--color-foreground-xlight);
  margin-bottom: var(--size-medium);
  margin-top: calc(-1 * var(--size-medium));
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Card_titleIcon {
  display: inline-flex;
  flex-shrink: 0;
  margin-right: var(--size-small);
}

.Card_title-text {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
}

.Card_title svg {
  width: 18px;
  height: 18px;
  display: inline-flex;
  transition: transform var(--anim-ease-out) 150ms;
}

.Card_title svg path {
  fill: var(--color-foreground-medium);
}

.Card_subtitle {
  font-size: var(--font-size-small);
  color: var(--color-foreground-medium);
  font-weight: var(--font-weight-normal);
}

.Card_titleInner {
  display: flex;
  gap: 3px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.Card_footer {
  margin-top: var(--size-medium);
}

.Card-isCollapsable .Card_title {
  transition: margin var(--anim-ease-out) 150ms, padding var(--anim-ease-out) 150ms;
  cursor: pointer;
}

.Card-isCollapsable .Card_title:not(.is-expanded) {
  border: 0;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

.Card-isCollapsable .Card_title.is-expanded svg {
  transform: rotate(90deg);
}

.Card.color-warning {
  border: 1px solid var(--color-warning-medium);
  background-image: linear-gradient(to right, var(--color-warning-xlight), var(--color-warning-xlight)),
    linear-gradient(to right, var(--color-background), var(--color-background));
}

.Card.color-accent {
  border: none;
  background-image: linear-gradient(to right, var(--color-accent-light), var(--color-accent-light)),
    linear-gradient(to right, var(--color-background), var(--color-background));
  box-shadow: none;
}

[data-theme="dark"] {
  .Card {
    border-color: var(--color-foreground-xxlight);
    box-shadow: none;
  }

  .Card-muted {
    border-color: transparent;
  }
}

.Card .Card_highlight {
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: inherit;
  background-color: var(--color-accent-xlight);
  z-index: 1;
}

.Card-isHighlighted {
  border-color: var(--color-accent-medium) !important;
}

[data-theme="dark"] .Card .Card_highlight {
  background-color: var(--color-accent-light);
}

@media only screen and (min-width: 992px) {
  .Card-isClickable {
    cursor: pointer;
    transition: opacity var(--anim-ease-out) 300ms;
  }

  .Card-isClickable:hover {
    opacity: var(--hover-opacity);
    transition-duration: 50ms;
  }
}
