.wrapper {
  display: inline-flex;
  flex-direction: column;
  gap: var(--size-medium);
  align-items: center;
  color: var(--color-foreground-medium);
  flex-shrink: 0;
}

.small .spinner {
  width: 14px;
  height: 14px;
}

.spinner {
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 2px;

  --animation-duration: 0.5s;
}

.spinner > div {
  background-color: currentColor;
  animation: blink linear infinite;
  animation-duration: calc((var(--animation-duration) / 3) * 4);
  opacity: 0;
  border-radius: 1.5px;
}

.spinner > div:nth-child(1) {
  animation-delay: 0ms;
}

.spinner > div:nth-child(2) {
  animation-delay: calc(var(--animation-duration) / 4);
}

.spinner > div:nth-child(3) {
  animation-delay: calc((var(--animation-duration) / 4) * 2);
}

.spinner > div:nth-child(4) {
  animation-delay: calc((var(--animation-duration) / 4) * 3);
}

.wrapper label {
  font-size: var(--font-size-small);
  color: currentColor;
}

@keyframes blink {
  0%,
  24.9% {
    opacity: 1;
  }

  25%,
  99.9% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.1;
  }
}
