.wrapper {
  position: relative;
  aspect-ratio: 1 / 1;
  border-radius: var(--border-radius-normal);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper svg {
  width: 100%;
  height: 100%;
}

.wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.wrapper [data-part="background"] {
  fill: var(--color-foreground-xxlight);
}

.wrapper [data-part="body"] {
  fill: var(--color-foreground-medium);
  fill-opacity: 0.15;
}

.wrapper [data-part="symbol"] {
  fill: var(--color-foreground-medium);
  fill-opacity: 0.3;
}

.wrapper [data-part="overlay"] {
  fill: var(--color-foreground-light);
}

.wrapper [data-part="overlayLight"] {
  fill: var(--color-foreground-xlight);
}

.wrapper [data-part="hair"] {
  fill: #ccc;
}

.wrapper [data-part="overlayDark"] {
  fill: #aaa;
}

[data-theme="dark"] {
  .wrapper [data-part="background"] {
    fill: var(--color-foreground-xlight);
  }

  .wrapper [data-part="body"] {
    fill: var(--color-foreground-medium);
  }

  .wrapper [data-part="overlay"] {
    fill: var(--color-background);
  }

  .wrapper [data-part="overlayLight"] {
    fill: var(--color-background);
    fill-opacity: 0.5;
  }

  .wrapper [data-part="hair"] {
    fill: var(--color-background-contrast);
    fill-opacity: 1;
  }

  .wrapper [data-part="overlayDark"] {
    fill: #111;
  }
}
