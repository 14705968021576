.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 0;
}

.info {
  display: flex;
  flex-direction: column;
  gap: var(--size-small);
  align-items: center;
  justify-content: center;
  margin-bottom: var(--size-medium);
}

.CurrentPrice {
  display: inline-flex;
  padding: var(--size-xsmall) var(--size-medium);
  border-radius: 999em;
  color: var(--color-foreground);
  border: 1px solid var(--color-foreground-xlight);
  text-align: center;
  justify-content: center;
  align-self: center;
}

.buttonSpacer {
  flex: 1;
}

.toggle {
  display: inline-flex;
  align-self: center;
  border-radius: var(--border-radius-normal);
  overflow: hidden;
  height: var(--min-touch-size);
  position: relative;
  background-color: var(--color-foreground-xxlight);
}

.toggleItem {
  padding: var(--size-xsmall) var(--size-medium);
  font-weight: var(--font-weight-bold);
  align-items: center;
  display: inline-flex;
  width: 100px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
}

.toggleItem.selected {
  background-color: var(--color-accent);
  color: var(--color-background);
}

@media only screen and (min-width: 992px) {
  .toggleItem {
    cursor: pointer;
    transition: all var(--anim-ease-out) 150ms;
  }

  .toggleItem:not(.selected):hover {
    opacity: var(--hover-opacity);
  }

  .toggleItem:not(.selected):active {
    opacity: var(--pressed-opacity);
  }

  .info {
    flex-direction: row;
  }
}
