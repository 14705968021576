.trailingChevron {
  width: 18px;
  height: 18px;
  display: inline-flex;
}

.trailingChevron svg {
  width: 100%;
  height: 100%;
}

.trailingChevron svg path {
  fill: var(--color-foreground-medium);
}
