.token {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: var(--color-accent);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.token svg {
  width: 100% !important;
  height: 100% !important;
  display: inline-flex;
}

.token svg path {
  fill: var(--color-background);
}
