.BeamCode {
  background-color: var(--color-qr-background);
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-rows: 20px auto 20px;
  grid-template-areas:
    ". url ."
    "currency qr date"
    ". name .";
  border-radius: 20px;
  position: relative;
  border: 1px solid var(--color-foreground-xxlight);
  transition: all ease 0.5s, transform var(--anim-ease-out) 0.5s;
  box-shadow: 0px 0px 94px 0px rgba(0, 0, 0, 0.05), 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
}

.BeamCode_wrapper {
  width: 200px;
  height: 200px;
  position: relative;
  transition: all var(--anim-ease-out) 2s;
  user-select: none;
}

.BeamCode_rotationContainer {
  transform: rotate(45deg);
  transform-origin: center center;
  width: 100%;
  height: 100%;
}

.BeamCode_logo {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  background-color: var(--color-qr-foreground);
  z-index: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all var(--anim-ease-out) 0.5s;
  opacity: 0;
  transform: scale(0.9);
}

.loaded .BeamCode_logo {
  opacity: 1;
  transform: scale(1);
}

.BeamCode_logo svg {
  width: 32px;
  height: 32px;
  display: inline-flex;
}

.BeamCode_logo svg path {
  stroke: var(--color-qr-background);
}

@keyframes placeholder {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 400% 400%;
  }
}

.BeamCode_placeholder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    var(--color-foreground-xlight),
    var(--color-background-contrast),
    var(--color-foreground-xlight)
  );
  background-size: 400% 100%;
  background-repeat: repeat;
  animation: placeholder linear 2s infinite forwards;
  transform: rotate(-45deg) scale(0.9);
  transition: opacity ease 1s;
}

.BeamCode_qr {
  grid-area: qr;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BeamCode_qr > div {
  display: inline-flex;
}

.BeamCode_qr canvas {
  width: 100%;
  height: 100%;
}

.BeamCode_url {
  grid-area: url;
}

.BeamCode_date {
  grid-area: date;
  flex-direction: column;
}

.BeamCode_currency {
  grid-area: currency;
  flex-direction: column;
}

.BeamCode_name {
  grid-area: name;
}

.BeamCode_decoration {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 8px;
  color: #9f988e;
  z-index: 2;
  overflow: hidden;
}

.BeamCode_decoration span {
  display: inline-flex;
  text-align: center;
  text-indent: 8px;
  white-space: nowrap;
  background-color: var(--color-qr-background);
  font-weight: 800;
  overflow: hidden;
  align-items: center;
  position: relative;
}

.BeamCode_decoration span.beamnameLoader {
  animation: placeholder linear 2s infinite forwards;
  border-radius: 999em;
  flex-shrink: 0;
  height: 8px;
  width: 60px;
  display: inline-flex;
  position: absolute;
  left: calc(50% - 30px);
  z-index: 5;
  background: linear-gradient(90deg, rgba(159, 152, 142, 0.2), rgba(159, 152, 142, 0.1), rgba(159, 152, 142, 0.2)),
    linear-gradient(90deg, var(--color-qr-background), var(--color-qr-background));
  background-size: 400% 100%;
  background-repeat: repeat;
  box-shadow: 0 0 0 5px var(--color-qr-background);
}

.BeamCode_amount {
  letter-spacing: 4px;
}

.BeamCode_currency span {
  transform: rotate(-90deg);
}

.BeamCode_date span {
  transform: rotate(-90deg);
}

.BeamCode_line {
  position: absolute;
  left: 9px;
  right: 9px;
  top: 9px;
  bottom: 9px;
  border-radius: 13px;
  border: 1px solid #e5d2b6;
  z-index: 1;
  opacity: 0.5;
}

.glow {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--color-accent);
  width: 80px;
  height: 80px;
  border-radius: 999em;
  filter: blur(20px);
  pointer-events: none;
  opacity: 0;
}

.glowWrapper {
  position: absolute;
  left: -20px;
  right: -20px;
  top: -20px;
  bottom: -20px;
  pointer-events: none;
  overflow: hidden;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  mix-blend-mode: color-dodge;
  z-index: 10;
}

.BeamCode_wrapper:hover {
  transition: all var(--anim-ease-out) 1s;
  cursor: none;
}

.BeamCode_wrapper:active {
  transform: scale(0.98);
  opacity: var(--pressed-opacity);
  transition-duration: 100ms;
}

.networkTitle {
  display: flex;
  flex-direction: row;
  gap: var(--size-xsmall);
  align-items: center;
}

.networkTitle p {
  font-weight: var(--font-weight-bold);
}

.networkTitle svg path {
  fill: var(--color-foreground-medium);
}

@media only screen and (min-width: 992px) {
  .BeamCode_wrapper:hover .glow {
    opacity: 0.7;
  }

  .BeamCode_wrapper:hover {
    transform: scale(1.06);
  }

  .BeamCode_wrapper:active {
    transform: scale(1);
  }
}
