.TOSConfirmation__iframe {
  width: 100%;
  max-height: 360px;
  height: 80vh;
}

.TOSConfirmation__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
