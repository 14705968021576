.Scan {
  grid-area: body;
  display: flex;
  padding-bottom: var(--size-large);
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  --color-foreground: var(--color-lightContent);
  --color-background: black;
  --color-foreground-medium: var(--color-lightContent-medium);
  --color-foreground-light: var(--color-lightContent-light);
  --color-foreground-xxlight: var(--color-lightContent-xxlight);
  --color-foreground-xlight: var(--color-lightContent-xlight);
}

.Scan video {
  background-color: black;
}

.Scan_toContainer {
  display: flex;
  background-color: var(--color-foreground-xxlight);
  color: var(--color-foreground);
  padding: var(--size-small) var(--size-medium);
  border-radius: 9999em;
  align-items: center;
  gap: 3px;
}

.Scan_toContainer svg {
  width: 12px;
  height: 12px;
}

.Scan_toContainer svg path {
  fill: var(--color-foreground-medium);
}

.Scan_overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  backdrop-filter: blur(20px);
  pointer-events: none;
}

.Scan_overlay::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: black;
  opacity: 0.8;
}

.Scan_viewFinder {
  position: absolute;
  left: 0;
  right: 0;
  top: var(--navbar-height);
  bottom: var(--bottom-bar-height);
  z-index: 1;
  display: grid;
  justify-content: center;
  grid-template-rows: 1fr min-content 1fr;
  gap: 0;
  grid-template-areas:
    "."
    "reticle"
    "text";
}

.Scan_viewFinderInner {
  height: 280px;
  grid-area: reticle;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Scan_viewFinderInner div {
  width: 200px;
  height: 200px;
  transform: rotate(45deg);
  display: inline-flex;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.35), 0 0 0 9999em rgba(0, 0, 0, 0.6);
}

.Scan_viewFinderText {
  grid-area: text;
  padding-top: var(--size-medium);
  padding-bottom: var(--size-medium);
  display: flex;
  align-items: center;
}

.Scan_viewFinderText span {
  display: flex;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 0 var(--size-medium);
  border-radius: 999em;
  color: var(--color-foreground-medium);
}

.Scan_actions {
  display: flex;
  gap: var(--size-medium);
  width: 100%;
  max-width: 380px;

  --color-button-background: var(--color-lightContent);
  --color-button-foreground: black;
}

@media (max-height: 680px) {
  .Scan_toContainer {
    font-size: var(--font-size-small);
  }
}
