.SaveAccessCard_lightText {
  color: var(--color-foreground-medium);
}

.SavedAccountsCard-row {
  display: flex;
  align-items: center;
}

.SavedAccountsCard-icon {
  color: var(--color-foreground);
  padding-top: 3px;
}

.SavedAccountsCard-icon svg path {
  fill: var(--color-foreground);
}
