.toggle {
  margin: 0;
  display: flex;
  align-items: center;
  gap: var(--size-small);
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-bold);
  color: var(--color-foreground-medium);
  cursor: pointer;
  justify-content: space-between;
}

.toggleIcon {
  transition: transform var(--anim-ease-out) 300ms;
}

.toggle > div {
  display: inline-flex;
  align-items: center;
  gap: var(--size-small);
  font-weight: normal;
  font-size: var(--font-size-small);
}

.networkDetails {
  padding-top: var(--size-medium);
}

.networkDetailsBorder {
  border-top: 1px solid var(--color-foreground-xlight);
}

.transferIcon {
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-foreground-xxlight);
  border-radius: var(--border-radius-normal);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.transferIcon span {
  position: absolute;
  top: calc(50% - 8px);
  right: -8px;
  width: 16px;
  height: 16px;
  background-color: var(--color-background-contrast);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--color-foreground-xlight);
}

.transferIcon span svg path {
  stroke: var(--color-accent);
}

.transferIcon svg {
  display: inline-flex;
}

.networkName {
  display: flex;
  gap: var(--size-xsmall);
  align-items: center;
  color: var(--color-foreground-medium);
}

.networkName svg path {
  fill: currentColor;
}

.rebaseIcon span {
  border: none;
}

.rebaseIcon span svg path {
  stroke: var(--color-foreground-medium);
}

.receiveIcon span {
  transform: rotate(180deg);
}

.receiveIcon span svg path {
  stroke: var(--color-success);
}

.rebaseLearnMore {
  margin-top: var(--size-small);
}

.rebaseRow {
  color: var(--color-accent);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: calc(-1 * var(--size-medium));
    right: calc(-1 * var(--size-medium));
    top: 0;
    bottom: 0;
    background-color: var(--color-accent-light);
    z-index: 0;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--size-xxsmall);
}

.rebaseInfoCard {
  display: flex;
  flex-direction: column;
  gap: var(--size-small);
  color: var(--color-accent);
}

@media only screen and (min-width: 992px) {
  .rebaseRow {
    &:before {
      border-radius: var(--border-radius-normal);
    }
  }
}
