.form {
  display: flex;
  flex-direction: column;
  gap: var(--size-medium);
}

.formRow {
  display: flex;
  gap: var(--size-small);
}

.formRow > * {
  flex: 1;
}
