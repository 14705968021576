body {
  margin: 0;
  line-height: var(--line-height-normal);
}

svg {
  transform: translateZ(0);
}

@font-face {
  font-family: SofiaSans;
  /* webpackIgnore: true */
  src: url("/fonts/SofiaSans-Regular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SofiaSans;
  /* webpackIgnore: true */
  src: url("/fonts/SofiaSans-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.font-monospace {
  font-family: var(--font-stack-monospace);
}

.ant-input {
  font-size: 16px !important;
}

.tokens-segment .ant-segmented-item-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tokens-segment .ant-segmented-item-icon {
  height: 16px;
  line-height: 16px;
}

.tokens-segment .ant-segmented-item-icon img {
  vertical-align: top;
}

/* forms */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

form .form-item {
  margin: 0;
}

.input.ant-input-affix-wrapper input,
input.input,
.input.ant-select .ant-select-selector {
  height: 46px;
  background-color: transparent;
  color: var(--color-foreground);
}

.input::placeholder,
.input input::placeholder,
.input.ant-select .ant-select-selection-placeholder {
  color: var(--color-foreground);
  opacity: 0.35;
}

.Form {
  line-height: inherit;
  color: inherit;
  font-family: inherit;
  font-size: unset;
}

.input.ant-input-affix-wrapper,
input.input,
.input.ant-select .ant-select-selector {
  margin: 0;
  padding: 0 var(--size-small);
  background-color: var(--color-background);
  border: 1px solid var(--color-foreground-xlight);
  transition: all var(--anim-ease-out) 150ms;
}

.input.ant-select .ant-select-selector {
  padding: 0 var(--size-small);
  height: 46px !important;
}

.input.ant-select .ant-select-selector .ant-select-selection-placeholder,
.input.ant-select .ant-select-selector .ant-select-selection-item {
  line-height: 46px;
}

.input.ant-select .ant-select-arrow {
  color: var(--color-foreground-medium);
}

.input.ant-select .ant-select-selector .ant-select-selection-item {
  color: var(--color-foreground);
}

.input.ant-select.ant-select-disabled .ant-select-selection-item {
  opacity: 0.4;
}

.input.ant-input-affix-wrapper:hover,
input.input:hover {
  border-color: var(--color-foreground-light) !important;
}

.input.ant-input-affix-wrapper-focused,
.input.ant-input-affix-wrapper-focused:hover,
input.input:focus {
  border-color: var(--color-accent) !important;
}

.input.ant-input-affix-wrapper-status-error,
.input.ant-input-affix-wrapper-status-error:hover,
input.input.ant-input-status-error,
.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: var(--color-warning) !important;
}

.Form .ant-form-item-explain-error {
  color: var(--color-warning);
}

input[type="number"] {
  -moz-appearance: textfield;
}

input.input[type="date"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  display: flex;
  align-items: center;
}

input.input[type="date"]:empty::-webkit-datetime-edit-text,
input.input[type="date"]:empty::-webkit-datetime-edit-month-field,
input.input[type="date"]:empty::-webkit-datetime-edit-day-field,
input.input[type="date"]:empty::-webkit-datetime-edit-year-field {
  color: var(--color-foreground);
  font-family: var(--font-stack-default);
  padding-top: 0;
  padding-bottom: 0;
}

#onramper-widget {
  border-radius: var(--border-radius-normal);
  margin: auto;
  width: 100%;
  height: 100%;
  min-height: 600px;
  border: none;
}

:root {
  --min-touch-size: 32px;

  --border-radius-normal: 8px;
  --border-radius-large: 18px;

  --font-size-bottom-bar: 10px;
  --font-size-amount-entry: var(--font-size-giant);
  --font-size-numberpad-key: 30px;
  --font-size-xsmall: 11px;
  --font-size-small: 13px;
  --font-size-body: 16px;
  --font-size-large: 18px;
  --font-size-xlarge: 22px;
  --font-size-xxlarge: 38px;
  --font-size-giant: 52px;

  --font-stack-default: SofiaSans, system, -apple-system, \.SFNSText-Regular, San Francisco, Roboto, Segoe UI,
    Helvetica Neue, Lucida Grande, sans-serif;
  --font-stack-monospace: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter",
    "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier,
    monospace;

  --font-weight-normal: 500;
  --font-weight-bold: 700;

  --bottom-bar-height: 60px;
  --navbar-height: 60px;
  --row-min-height: 58px;
  --sidebar-width: 280px;

  --size-xsmall: 4px;
  --size-small: 8px;
  --size-medium: 16px;
  --size-large: 32px;
  --size-xlarge: 52px;

  --anim-ease-out: cubic-bezier(0.25, 1, 0.5, 1);

  --color-foreground-rgb: 24, 17, 0;
  --color-background-rgb: 255, 255, 255;
  --color-background-contrast-rgb: 249, 249, 249;
  --color-accent-rgb: 57, 131, 225;
  --color-lighter-accent-rgb: 47, 128, 237;

  --color-success: #59c785;
  --color-green: #50a975;
  --color-error: #eb5757;

  --color-foreground: rgba(var(--color-foreground-rgb), 1);
  --color-foreground-medium: rgba(var(--color-foreground-rgb), 0.5);
  --color-foreground-light: rgba(var(--color-foreground-rgb), 0.2);
  --color-foreground-xlight: rgba(var(--color-foreground-rgb), 0.05);
  --color-foreground-xxlight: rgba(var(--color-foreground-rgb), 0.02);
  --color-foreground-transparent: rgba(var(--color-foreground-rgb), 0);

  --color-background: rgba(var(--color-background-rgb), 1);
  --color-background-transparent: rgba(var(--color-background-rgb), 0);
  --color-background-medium: rgba(var(--color-background-rgb), 0.8);
  --color-background-contrast: rgba(var(--color-background-contrast-rgb), 1);
  --color-background-contrast-transparent: rgba(var(--color-background-contrast-rgb), 0);

  --color-accent: rgba(var(--color-accent-rgb), 1);
  --color-accent-medium: rgba(var(--color-accent-rgb), 0.5);
  --color-accent-light: rgba(var(--color-accent-rgb), 0.1);
  --color-accent-xlight: rgba(var(--color-accent-rgb), 0.05);

  --color-lighter-accent: rgba(var(--color-lighter-accent-rgb), 1);
  --color-lighter-accent-medium: rgba(var(--color-lighter-accent-rgb), 0.5);
  --color-lighter-accent-light: rgba(var(--color-lighter-accent-rgb), 0.1);
  --color-lighter-accent-xlight: rgba(var(--color-lighter-accent-rgb), 0.05);

  --color-lightContent-rgb: 255, 255, 255;
  --color-lightContent: rgba(var(--color-lightContent-rgb), 1);
  --color-lightContent-medium: rgba(var(--color-lightContent-rgb), 0.7);
  --color-lightContent-light: rgba(var(--color-lightContent-rgb), 0.3);
  --color-lightContent-xlight: rgba(var(--color-lightContent-rgb), 0.12);
  --color-lightContent-xxlight: rgba(var(--color-lightContent-rgb), 0.05);

  --color-qr-background: white;
  --color-qr-foreground: var(--color-accent);

  --color-warning-rgb: 255, 153, 80;
  --color-warning: rgba(var(--color-warning-rgb), 1);
  --color-warning-medium: rgba(var(--color-warning-rgb), 0.5);
  --color-warning-xlight: rgba(var(--color-warning-rgb), 0.06);
  --color-merchant-rgb: 111, 142, 255;

  --loader-anim-path-length: 71.79px;

  --hover-opacity: 0.64;
  --pressed-opacity: 0.3;
  --disabled-opacity: 0.2;

  --safe-area-height: calc(100dvh - var(--navbar-height) - var(--bottom-bar-height) - var(--size-medium));

  --line-height-normal: 1.2;

  --color-button-foreground: var(--color-lightContent);
  --color-button-background: var(--color-accent);

  --animated-number-fade-start: var(--color-background-contrast-transparent);
  --animated-number-fade-end: var(--color-background-contrast);

  color-scheme: auto;
}

@media (min-width: 1000px) {
  :root {
    --font-size-giant: 80px;
  }
}

[data-merchant-mode="true"],
[data-theme="dark"][data-merchant-mode="true"] {
  --color-accent-rgb: var(--color-merchant-rgb);
}

[data-theme="dark"] {
  --color-foreground-rgb: 249, 251, 254;
  --color-background-rgb: 44, 46, 49;
  --color-accent-rgb: 106, 154, 234;
  --color-background-contrast-rgb: 28, 30, 33;

  --color-foreground-xxlight: rgba(var(--color-foreground-rgb), 0.05);
  --color-foreground-xlight: rgba(var(--color-foreground-rgb), 0.1);
  --color-foreground-light: rgba(var(--color-foreground-rgb), 0.25);
  --color-foreground-medium: rgba(var(--color-foreground-rgb), 0.6);

  --color-qr-background: var(--color-foreground);
  --color-qr-foreground: var(--color-accent);

  color-scheme: dark;
}

html,
body {
  color: var(--color-foreground);
  background: var(--color-background-contrast);
  -webkit-font-smoothing: antialiased;
  font-family: var(--font-stack-default);
  overflow-x: hidden;
}

html.disable-body-scroll,
html.sidebar-open,
html.bottom-sheet-open {
  overflow-y: hidden;
}

a {
  color: var(--color-accent);
  text-decoration-color: var(--color-accent-light);
}

#root,
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.AppWrapper {
  flex: 1;
  width: 100%;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--size-small);
}

.list li {
  padding: 0;
  margin: 0;
  display: block;
  padding-left: 24px;
  position: relative;
}

.list li::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-flex;
  background-color: var(--color-foreground-light);
  position: absolute;
  left: 4px;
  top: 4px;
  transform: rotate(45deg);
  border-radius: 2px;
}

.AnimatedNumberItem__wrapper {
  transition-timing-function: var(--anim-ease-out);
  user-select: none;
}

.AnimatedNumberItem {
  position: relative;
  overflow: hidden;
}

.AnimatedNumberItem::before,
.AnimatedNumberItem::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(to top, var(--animated-number-fade-start), var(--animated-number-fade-end));
  content: "";
  z-index: 1;
}

.AnimatedNumberItem::after {
  top: auto;
  bottom: 0;
  background: linear-gradient(to bottom, var(--animated-number-fade-start), var(--animated-number-fade-end));
}

[data-radix-popper-content-wrapper] {
  z-index: 9999 !important;
  pointer-events: none !important;
}

@media only screen and (min-width: 992px) {
  :root {
    --safe-area-height: calc(100dvh - var(--navbar-height) - var(--bottom-bar-height) - var(--size-medium));
  }

  .AnimatedNumberItem::before,
  .AnimatedNumberItem::after {
    height: 12px;
  }
}
