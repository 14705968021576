.button {
  width: var(--min-touch-size);
  height: var(--min-touch-size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  appearance: none;
  border: 0;
  background-color: transparent;
  flex-shrink: 0;
  transition: opacity ease-out 300ms, transform ease-out 300ms;
}

.button.bg {
  border-radius: 6px;
  background-color: var(--color-foreground-xlight);
}

.button svg {
  display: inline-flex;
}

.button svg path {
  fill: var(--color-foreground-medium);
}

.button.small {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.button.small svg {
  width: 16px;
  height: 16px;
}

.button.xsmall {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.button.xsmall svg {
  width: 13px;
  height: 13px;
}

.button:active {
  transform: translateY(1px);
}

.button:active {
  opacity: var(--pressed-opacity) !important;
  transition-duration: 50ms;
}

@media only screen and (min-width: 992px) {
  .button {
    cursor: pointer;
  }

  .button:hover {
    opacity: 0.8;
  }
}
