.wrapper {
  --thumb-size: 28px;
  display: flex;
  height: var(--thumb-size);
  width: calc(var(--thumb-size) * 2);
  border-radius: 999em;
  background-color: var(--color-foreground-xlight);
  transition: background-color ease 100ms;
  justify-content: flex-start;
  align-items: center;
  padding: 3px;
}

.wrapper.enabled {
  justify-content: flex-end;
}

.thumb {
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.thumbInner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(to right, var(--color-foreground-xxlight), var(--color-foreground-xxlight)),
    linear-gradient(to right, var(--color-background), var(--color-background));
  transition: all ease-out 50ms;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background ease 100ms;
}

.thumbInner svg {
  width: 80%;
  height: 80%;
  display: inline-flex;
}

.enabled .thumbInner {
  background: linear-gradient(to right, var(--color-accent), var(--color-accent));
}

.wrapper.enabled {
  background-color: var(--color-accent-light);
}

@media only screen and (min-width: 992px) {
  .wrapper {
    cursor: pointer;
  }

  .wrapper:hover .thumbInner {
    transform: scale(1.1);
  }
}
