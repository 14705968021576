.Badge {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: var(--size-small);
  padding: 6px 10px;
  border-radius: 999em;
  background-color: var(--color-accent-xlight);
  color: var(--color-accent);
  white-space: nowrap;
  font-weight: var(--font-weight-normal);
}

.Badge svg {
  flex-shrink: 0;
}

.Badge svg path {
  fill: currentColor;
}

.Badge-small {
  font-size: var(--font-size-small);
  padding: 4px 10px;
  gap: var(--size-xsmall);
}

.Badge-small svg {
  width: 12px;
  height: 12px;
}

.Badge-middle {
  font-size: var(--font-size-body);
}

.Badge-outline {
  border: 1px solid var(--color-foreground-xlight);
  background-color: transparent;
  color: var(--color-foreground-medium);
}
