.wrapper {
  position: relative;
  width: 100%;
}

.input {
  height: 46px;
  margin: 0;
  padding: 0 var(--size-small);
  background-color: var(--color-background);
  border: 1px solid var(--color-foreground-xlight);
  transition: all var(--anim-ease-out) 150ms;
  border-radius: var(--border-radius-normal);
  font-size: var(--font-size-body);
  width: 100%;
  color: var(--color-foreground);
}

.input[disabled] {
  color: var(--color-foreground-medium);
  cursor: not-allowed;
}

.input:focus:not([disabled]) {
  box-shadow: none;
  outline: none;
  border-color: var(--color-accent) !important;
}

.input[data-invalid]:not([disabled]),
.input[data-invalid] {
  border-color: var(--color-warning) !important;
}

.input:hover:not([disabled]) {
  border-color: var(--color-foreground-light);
}

.input::placeholder {
  color: var(--color-foreground);
  opacity: 0.25;
}

.input[type="date"]::-webkit-datetime-edit-text,
.input[type="date"]::-webkit-datetime-edit-month-field,
.input[type="date"]::-webkit-datetime-edit-day-field,
.input[type="date"]::-webkit-datetime-edit-year-field {
  color: var(--color-foreground);
  font-family: var(--font-stack-default);
  padding-top: 0;
  padding-bottom: 0;
}

.input[type="date"][disabled]::-webkit-datetime-edit-text,
.input[type="date"][disabled]::-webkit-datetime-edit-month-field,
.input[type="date"][disabled]::-webkit-datetime-edit-day-field,
.input[type="date"][disabled]::-webkit-datetime-edit-year-field {
  color: var(--color-foreground-light);
}

.label {
  position: absolute;
  top: -7px;
  left: calc(var(--size-small) - var(--size-xsmall) + 1px);
  font-size: var(--font-size-small);
  background-color: var(--color-background);
  padding: 0 var(--size-xsmall);
  display: block;
  color: var(--color-foreground-medium);
  z-index: 1;
}

.label[data-invalid] {
  color: var(--color-warning);
}

.messageContainer {
  display: flex;
  flex-direction: row;
  gap: var(--size-small);
  color: var(--color-warning);
  font-size: var(--font-size-small);
  padding: 2px var(--size-small);
}
