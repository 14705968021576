.wrapper {
  display: flex;
  gap: var(--size-small);
  flex-direction: column;
}

.recommendedTitle {
  color: var(--color-foreground-medium);
  margin-bottom: calc(-1 * var(--size-xsmall));
}

.otherTitle {
  margin-bottom: var(--size-medium);
}

.method_container {
  display: grid;
  flex-direction: column;
  height: 100%;
  grid-template-rows: min-content auto min-content;
  align-items: center;
  gap: var(--size-medium);
}

.line {
  height: 1px;
  background-color: var(--color-foreground-xlight);
  margin: var(--size-medium) 0;
}

.methodDescription {
  padding: var(--size-medium);
  padding-top: 0;
}

.fundRowChevron svg {
  width: 18px;
  height: 18px;
  display: inline-flex;
}

.fundRowChevron svg path {
  fill: var(--color-foreground-medium);
}

.fundRowIcon {
  border-radius: 6px;
  background: var(--color-accent-xlight);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.fundRowIcon svg path {
  fill: var(--color-accent);
}

.fund_option_container {
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* ShowBeamAddress */

.show_button {
  margin: 24px 0;
  text-align: center;
}
