.MigrationSheet__title {
  margin-top: 8px;
  font-size: 18px;
  text-align: center;
  font-weight: var(--font-weight-bold);
}

.MigrationSheet__box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 36px;
  gap: 32px;
  background: var(--color-foreground-xxlight);
  align-items: center;
}

.MigrationSheet__box span svg path {
  fill: var(--color-foreground);
}

.MigrationSheet__box span svg {
  opacity: 0.3;
}

.MigrationSheet__box img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.MigrationSheet__beam-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 47px;
  height: 47px;
  background: var(--color-accent);
}

.MigrationSheet__toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--color-foreground-medium);
}

.MigrationSheet__content {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: space-between;
}

.MigrationSheet__btn-text {
  color: var(--color-foreground-medium);
  text-align: center;
}

.MigrationSheet__contact-us {
  display: block;
  color: var(--color-foreground);
  text-align: center;
  border-bottom: 1px solid var(--color-foreground-light);
}
