/*
Overrides to the standard react-toastify styling
*/

.Toastify__toast--error {
  background: #cf6f6f !important;
}

.Toastify__toast--success {
  background: #6fcf97 !important;
}

.Toastify__toast--warning {
  background: #003249 !important; /* @PETE PLZ HELP */
}

.Toastify__toast--info {
  background: #2f80ed !important;
}

.Toastify {
  position: relative;
  margin: 0;
}

.Toastify__toast-container--top-center {
  top: 0;
  left: 0;
  transform: none;
}

.Toastify__toast-container {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}

.Toastify__toast-container-temporary {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
}

.Toastify__toast-container-temporary > div:last-child {
  z-index: 9999;
}

.Toastify__toast {
  width: 100%;
  margin: 0 !important;
  box-shadow: none;
  height: 100%;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 var(--border-radius-normal) var(--border-radius-normal);
}

.Toastify__toast-body {
  width: 100%;
  color: #fff;
  font-family: var(--font-stack-default);
  font-size: var(--font-size-default);
  font-style: normal;
  padding: 4px;
  margin: 0;
}

.slide-in-top {
  animation: slide-in-top 0.3s var(--anim-ease-out) both;
}

.slide-out-top {
  animation: slide-out-top 0.3s var(--anim-ease-out) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-15 15:51:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */

@keyframes slide-in-top {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */

@keyframes slide-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
  }
}

@media only screen and (min-width: 992px) {
  .Toastify {
    margin-left: var(--sidebar-width);
  }
}
