.wrapper {
  background: var(--tooltip-background-color);
  user-select: none;
  animation-duration: 200ms;
  animation-timing-function: var(--anim-ease-out);
  will-change: transform, opacity;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-normal);
  padding: 2px 6px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  color: var(--tooltip-foreground-color);

  --tooltip-background-color: #111;
  --tooltip-foreground-color: var(--color-lightContent);
}

.wrapper[data-state="delayed-open"][data-side="top"] {
  animation-name: slideDownAndFade;
}

.wrapper[data-state="delayed-open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}

.wrapper[data-state="delayed-open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}

.wrapper[data-state="delayed-open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

.arrow {
  fill: var(--tooltip-background-color);
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
