.NoRecommendationsCard .Card_titleInner {
  gap: 8px;
}

.NoRecommendationsCard__iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-normal);
  background-color: var(--color-lighter-accent-xlight);
}

.NoRecommendationsCard .NavItem {
  grid-template-columns: [icon] 40px [text] 1fr;
  grid-row-gap: 4px;
  grid-column-gap: var(--size-medium);
}

.NoRecommendationsCard .NavItem__icon {
  height: 40px;
}
