.themeItemList {
  min-width: 200px;
  margin: calc(-1 * var(--size-xsmall));
  border-radius: inherit;
  overflow: hidden;
}

.themeItemList:not(.inSheet) .themeItem {
  border-radius: 0 !important;
}

.themeItemLeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--size-small);
}

.themeItemLeading svg path {
  fill: var(--color-foreground);
}

.themeItemSelected {
  background-color: var(--color-accent);
}
