.twitterButton {
  flex-shrink: 0;
}

.twitterFollowers {
  display: inline-flex;
  font-size: 11px;
  background-color: var(--color-foreground-xlight);
  color: var(--color-foreground-medium);
  padding: 0 4px;
  border-radius: 4px;
  font-weight: normal;
  position: relative;
  top: 1px;
  line-height: 14px;
}

.twitterFollowers span {
  position: relative;
  top: 0.5px;
}

.twitterButton svg path {
  fill: var(--color-foreground);
}
