.popover {
  display: inline-flex;
  position: relative;
}

.wrapper {
  position: fixed;
  z-index: 9999;
}

.inner {
  background-color: var(--color-background);
  padding: var(--size-xsmall);
  border-radius: var(--border-radius-normal);
  box-shadow: 0 0 0 1px var(--color-foreground-xxlight), 0 2px 5px rgba(0, 0, 0, 0.1);
}
