.Row {
  font-size: var(--font-size-body);
  color: var(--color-foreground);
}

.Row.compact .Row_content {
  min-height: 44px;
}

.Row_title {
  font-weight: var(--font-weight-bold);
  margin: 0;
  text-align: left;
}

.Row_subtitle {
  color: var(--color-foreground-medium);
  margin: 0;
  font-size: var(--font-size-small);
}

.Row_content {
  display: flex;
  width: 100%;
  gap: var(--size-medium);
  flex-direction: row;
  min-height: var(--row-min-height);
  align-items: stretch;
  padding: var(--size-small) 0;
}

.Row_body {
  display: flex;
  flex-direction: column;
  gap: 3px;
  flex: 1;
  justify-content: center;
}

.Row_trailing,
.Row_leading {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  align-items: center;
}

.Row_trailing {
  min-width: 0;
}

.Row_leading {
  text-align: left;
  justify-content: flex-start;
}

.Row.has-bottom-border {
  border-bottom: 1px solid var(--color-foreground-xlight);
}

.Row.has-horizontal-padding .Row_content {
  padding-left: var(--size-medium);
  padding-right: var(--size-medium);
}

.Row.has-bottom-border:last-child {
  border-bottom: 0;
}

.Row.deemphasized-title .Row_title {
  font-weight: var(--font-weight-normal);
  color: var(--color-foreground-medium);
}

.Row.verticalAlign-top .Row_body {
  justify-content: start;
}

.Row.verticalAlign-top .Row_trailing {
  align-items: start;
}

.Row.is-clickable .Row_content {
  transition: opacity ease-out 300ms;
}

.Row.is-clickable:active .Row_content {
  opacity: 0.3 !important;
  transition-duration: 50ms;
}

.Row.is-selected {
  background-color: var(--color-accent);
  color: var(--color-background);
  border-bottom: 0;
  border-radius: var(--border-radius-normal);
}

.Row.is-selected svg path {
  fill: var(--color-background);
}

@media only screen and (min-width: 992px) {
  .Row.is-clickable {
    cursor: pointer;
  }

  .Row:not(.Row.is-selected).is-clickable:hover .Row_content {
    opacity: 0.8;
    transition-duration: 50ms;
  }
}
