.withdraw_card svg path {
  fill: var(--color-foreground);
}

.withdraw_card--icon svg {
  width: 18px;
  height: 18px;
}

.withdraw_card--icon svg path {
  fill: var(--color-foreground-medium);
}
