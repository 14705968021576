.SavedBadge {
  display: flex;
  flex-direction: row;
  gap: var(--size-small);
  align-items: center;
  border-radius: 999em;
  padding: var(--size-small) var(--size-medium);
  border: 1px solid var(--color-foreground-xlight);
  white-space: nowrap;
  background-color: var(--color-background-contrast);
}

.SavedBadge p {
  transform: translateY(1px);
}

.SavedBadge svg {
  display: inline-flex;
}

.SavedBadge svg path {
  fill: var(--color-foreground);
}

[data-theme="dark"] {
  .SavedBadge {
    border-color: var(--color-foreground-light);
  }
}
