.BottomBar {
  display: flex;
  height: var(--bottom-bar-height);
  align-items: center;
  justify-content: stretch;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--color-foreground-xxlight);
  background: var(--color-background);
  box-shadow: 0 -3px 24px rgba(0, 0, 0, 0.05);
  z-index: 99;
}

.BottomBar-transparent {
  --color-foreground: var(--color-lightContent);
  --color-foreground-medium: var(--color-lightContent-medium);
  --color-foreground-light: var(--color-lightContent-light);
  --color-foreground-xlight: var(--color-lightContent-xxlight);
  --color-accent: var(--color-lightContent);

  background: transparent !important;
  box-shadow: none;
}

.BottomBar-transparent::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  content: "";
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.BottomBarItem {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
  appearance: none;
  border: 0;
  background-color: transparent;
  transition: all ease-out 300ms;
  position: relative;
  z-index: 1;
  height: var(--bottom-bar-height);
}

.BottomBarItem:active {
  opacity: 0.3;
  transition-duration: 50ms;
}

.BottomBarItem svg {
  width: 24px;
  height: 24px;
}

.BottomBarItem svg path {
  fill: var(--color-foreground-medium);
}

.BottomBarItem_title {
  font-size: var(--font-size-bottom-bar);
  color: var(--color-foreground-medium);
}

.BottomBarItem-selected svg path {
  fill: var(--color-foreground);
}

.BottomBarItem-selected .BottomBarItem_title {
  color: var(--color-foreground);
  font-weight: 600;
}

@media only screen and (min-width: 992px) {
  .BottomBar {
    background-color: transparent;
    box-shadow: none;
    border-color: var(--color-foreground-xlight);
    backdrop-filter: blur(12px);
  }

  .BottomBar::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    background-color: var(--color-background-contrast);
    opacity: 0.85;
  }

  .BottomBar-transparent::after {
    display: none;
  }

  .BottomBar:not(.BottomBar-omitOffsetDesktop) {
    padding-left: var(--sidebar-width);
  }

  .BottomBarItem {
    max-width: 180px;
    cursor: pointer;
  }

  .BottomBarItem:hover {
    opacity: 0.6;
    transition-duration: 50ms;
  }

  .BottomBarItem svg {
    transition: transform var(--anim-ease-out) 300ms;
  }

  .BottomBarItem-selected svg {
    transform: scale(1.15);
  }

  .BottomBarItem-selected:hover {
    opacity: 1;
  }

  .BottomBar {
    justify-content: center;
  }
}
