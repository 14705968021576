.body {
  height: var(--safe-area-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: calc(-1 * var(--size-medium));
  margin-right: calc(-1 * var(--size-medium));
}

.qr {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr auto 1fr;
  gap: var(--size-large);
}

.sheetCopy {
  color: var(--color-foreground-medium);
  padding: 0 var(--size-large);
  text-align: center;
}

.sheetTitle {
  display: flex;
  align-items: center;
  gap: var(--size-small);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
}

.amountWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: var(--size-medium);

  --animated-number-fade-start: var(--color-background-transparent);
  --animated-number-fade-end: var(--color-background);
}

.amountWrapper p {
  color: var(--color-foreground-medium);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: var(--font-weight-bold);
  border: 1px solid var(--color-foreground-xlight);
  padding: 4px 10px;
  border-radius: 999em;
  font-size: var(--font-size-small);
}

.amountWrapper h2 {
  font-weight: var(--font-weight-bold);
  color: var(--color-foreground);
  margin: 0;
}

.amountIcon {
  background-color: var(--color-foreground) !important;
}

.amountIcon svg path {
  fill: var(--color-background) !important;
}

.recent {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  border-radius: 999em;
  appearance: none;
  border: 1px solid var(--color-foreground-xlight);
  background-color: transparent;
  font-size: var(--font-size-body);
  transition: opacity ease 300ms;
  min-width: 68px;
  line-height: 1;
  color: var(--color-foreground-medium);
}

.recents label {
  color: var(--color-foreground-medium);
  font-size: var(--font-size-body);
}

.recents,
.amounts {
  display: flex;
  gap: var(--size-small);
  align-items: center;
  justify-content: center;
}

.recents.hidden {
  opacity: 0;
  pointer-events: none;
}

.multiAmountView {
  align-self: center;
  width: 100%;
  max-width: 300px;
  flex-direction: column;
  align-items: center;
  gap: var(--size-small);
  display: flex;
}

.multiAmountScrollWrapper {
  position: relative;
  max-width: 100%;

  &:before {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    background: linear-gradient(
      to right,
      var(--color-background-contrast),
      var(--color-background-contrast-transparent)
    );
    content: "";
    z-index: 1;
  }
}

.multiAmountScroll {
  flex: 1;
  display: flex;
  gap: var(--size-small);
  color: var(--color-foreground-medium);
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  padding: 0 10px;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-foreground-light);
    border-radius: 999em;
    border: 1px solid var(--color-background);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.multiAmountSpacer {
  flex: 1;
}

.amountsInner {
  display: flex;
  min-width: 0%;
  gap: var(--size-xsmall);
  max-width: 100%;
}

.spacer {
  width: var(--size-small);
  flex-shrink: 0;
}

.amount,
.totalAmount,
.equals,
.currentAmount,
.plus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  appearance: none;
  font-size: var(--font-size-xlarge);
  line-height: 1;
  flex-shrink: 0;
  background-color: transparent;
  padding: 0;
  flex-shrink: 0;
}

.multiAmountWrapper {
  gap: var(--size-small);
  display: flex;
  flex-direction: row;
}

.currentAmount {
  position: relative;
  opacity: 0.5;
  font-size: var(--font-size-giant);
  font-weight: var(--font-weight-bold);
  align-items: baseline;
  gap: var(--size-small);
}

.currentAmount.hasValue {
  opacity: 1;

  &::before {
    right: -3px;
    left: auto;
  }
}

.totalAmount,
.equals {
  font-size: var(--font-size-large);
  opacity: 0.5;
}

.equals,
.plus {
  min-width: 0;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}

.buttonWrapper {
  display: flex;
  gap: var(--size-small);
  padding-left: var(--size-medium);
  padding-right: var(--size-medium);
}

.buttonAdd {
  flex: 0;
}

.completeWrapper {
  display: flex;
  align-items: stretch;
  gap: var(--size-large);
  flex-direction: column;
  width: 100%;
  padding: var(--size-large) 0;
}

.checkmark {
  width: 100px;
  height: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-accent);
  align-self: center;
}

.checkmark svg {
  display: inline-flex;
  width: 60%;
  height: 60%;
}

.checkmark svg path {
  fill: var(--color-lightContent);
}

.tokenIcon {
  width: 25px;
  height: 25px;
  background-color: var(--color-foreground);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.tokenIcon svg {
  width: 18px;
  height: 18px;
  display: inline-flex;
}

.tokenIcon svg path {
  fill: var(--color-background);
}

@media only screen and (min-width: 992px) {
  .body {
    height: calc(var(--safe-area-height) - var(--size-large));
  }

  .buttonWrapper {
    width: 300px;
    padding: 0;
    margin: 0 auto;
  }

  .qr {
    padding: var(--size-medium);
  }

  .recent:hover {
    opacity: var(--hover-opacity);
    transition-duration: 50ms;
    cursor: pointer;
  }

  .recent:active {
    opacity: var(--pressed-opacity);
  }

  .tokenIcon {
    width: 36px;
    height: 36px;
    bottom: 5px;
  }

  .tokenIcon svg {
    width: 24px;
    height: 24px;
  }

  .amount:hover {
    cursor: pointer;
    opacity: var(--hover-opacity);
    text-decoration: line-through;
  }

  .amount:active {
    opacity: var(--pressed-opacity);
  }
}

@keyframes blink {
  0%,
  49.9% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: 0;
  }
}
