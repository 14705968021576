.Text-body,
.Text-header {
  margin: 0;
}

.Text-header {
  font-size: var(--font-size-xlarge);
  font-weight: var(--font-weight-bold);
}

.Text-upcase {
  font-size: var(--font-size-small);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.5px;
}

.Text-header-size-small {
  font-size: 18px;
}

.Text-body {
  font-size: var(--font-size-body);
}

.Text-header:last-child,
.Text-body:last-child .Text-upcase:last-child {
  margin-bottom: 0;
}

.Text-bold {
  font-weight: var(--font-weight-bold);
}

.Text-light {
  opacity: 0.7;
}

.Text-small {
  font-size: var(--font-size-small);
}

.Text-large {
  font-size: var(--font-size-large);
}

.Text-centered {
  text-align: center;
}

.Text-block .Text-body,
.Text-block .Text-header,
.Text-block .Text-upcase {
  margin-bottom: 0.75em;
}

.Text-block .Text-body:last-child,
.Text-block .Text-header:last-child,
.Text-block .Text-upcase:last-child {
  margin-bottom: 0;
}
