.BeamCode_logo {
  background-color: var(--color-qr-foreground);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BeamCode_logo svg {
  width: calc(100% - 30%);
  height: calc(100% - 30%);
  display: inline-flex;
}

.BeamCode_logo svg path {
  stroke: var(--color-qr-background);
}
