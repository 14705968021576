.container {
  display: flex;
  flex-direction: column;
}

.toggle {
  color: var(--color-foreground-medium);
  align-items: center;
  gap: var(--size-small);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-normal);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  padding: var(--size-xsmall) 0;
  transition: opacity ease 150ms;
}

.toggle.persists {
  margin-bottom: var(--size-small);
}

.toggle svg {
  position: relative;
  transition: transform var(--anim-ease-out) 150ms;
}

.toggle svg path {
  stroke: var(--color-foreground-medium);
}

.body {
  overflow: hidden;
}

@media only screen and (min-width: 992px) {
  .toggle:hover {
    opacity: var(--hover-opacity);
    transition-duration: 50ms;
  }

  .toggle:active {
    transform: translateY(1px);
  }
}
