.SidebarWrapper {
  min-height: 100dvh;
  display: flex;
}

.Sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateX(-100%);
  width: var(--sidebar-width);
  z-index: 999;
  will-change: transform;
  transition: transform var(--anim-ease-out) 300ms;
  box-shadow: none;
  background-color: var(--color-background);
}

.Sidebar.is-open {
  transform: translateX(0);
  box-shadow: 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.5);
}

.SidebarShim {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 998;
  will-change: opacity;
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--anim-ease-out) 200ms;
}

.SidebarShim.is-visible {
  opacity: 1;
  pointer-events: unset;
}

.Sidebar_inner {
  --sidebar-horizontal-padding: 20px;
  display: flex;
  flex-direction: column;
  padding-left: var(--sidebar-horizontal-padding);
  padding-right: var(--sidebar-horizontal-padding);
  justify-content: space-between;
  height: 100%;
  background-color: var(--color-background);
  gap: var(--size-medium);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-foreground-light);
    border-radius: 999em;
    border: 1px solid var(--color-background);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.Sidebar_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--navbar-height);
  flex-shrink: 0;
  position: sticky;
  top: 0;
  background-color: var(--color-background);
  z-index: 1;
}

.Sidebar_logo {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.5;
}

.Sidebar_logo svg {
  width: 88px;
  height: auto;
}

.Sidebar_logo svg path {
  fill: var(--color-foreground);
}

.Sidebar_close {
  width: var(--min-touch-size);
  height: var(--min-touch-size);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 6px;
  background-color: var(--color-foreground-xlight);
}

.Sidebar_close svg {
  width: 24px;
  height: 24px;
  display: inline-flex;
}

.Sidebar_close svg path {
  fill: var(--color-foreground-medium);
}

.Sidebar_account {
  background-color: var(--color-foreground-xxlight);
  border-radius: var(--border-radius-normal);
  padding: calc(var(--sidebar-horizontal-padding) / 2);
  position: relative;
  margin: 0 calc(-1 * var(--sidebar-horizontal-padding) / 2);
  margin-bottom: var(--size-medium);
}

.Sidebar_accountInner {
  gap: var(--size-small);
  display: flex;
  flex-direction: column;
  transition: opacity ease-out 150ms;
}

.Sidebar_account-warning {
  background-color: var(--color-warning-xlight);
  border: 1px solid var(--color-warning-medium);
}

.Sidebar_accountName,
.Sidebar_accountLink {
  font-size: var(--font-size-small);
}

.Sidebar_accountNameInner {
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  gap: var(--size-small);
  flex-direction: row;
}

.Sidebar_accountName {
  font-size: var(--font-size-large);
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.Sidebar_accountName.has-emojiname span:first-child {
  font-size: var(--font-size-xlarge) !important;
  letter-spacing: 4px;
}

.Sidebar_accountName span:first-child {
  color: var(--color-foreground) !important;
}

.Sidebar_accountName span:last-child {
  font-size: var(--font-size-small);
  color: var(--color-foreground-medium);
  font-weight: var(--font-weight-normal);
}

.Sidebar_accountName i {
  font-style: normal;
  opacity: 0.5;
}

.Sidebar_accountIcon {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border: 1px solid var(--color-foreground-xlight);
  border-radius: var(--border-radius-normal);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Sidebar_accountIcon img {
  width: 36px;
  height: 36px;
}

.Sidebar_accountLink,
.Sidebar_accountLink:hover {
  display: flex;
  align-items: center;
  gap: 0;
  color: var(--color-foreground-medium);
}

.Sidebar_accountLink:hover {
  opacity: var(--hover-opacity);
}

.Sidebar_login {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-foreground-medium);
}

.Sidebar_accountNameWrapper {
  gap: var(--size-small);
  display: flex;
  flex-direction: column;
}

.Sidebar_accountInfo {
  gap: var(--size-small);
  align-items: center;
}

.Sidebar_accountLink svg {
  width: 12px;
  height: auto;
  display: inline-flex;
}

.Sidebar_accountLink svg path {
  fill: var(--color-foreground-medium);
}

.Sidebar_account.is-loading {
  background-color: transparent;
}

.Sidebar_account.is-loading .Sidebar_accountLoader {
  opacity: 1;
}

.Sidebar_account.is-loading .Sidebar_accountInner {
  opacity: 0;
}

.Sidebar_accountLoader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    var(--color-foreground-transparent),
    var(--color-foreground-xxlight),
    var(--color-foreground-transparent)
  );
  background-repeat: repeat;
  border-radius: inherit;
  background-size: 300% 100%;
  animation: shimmer 1s linear infinite both;
  opacity: 0;
  pointer-events: none;
}

@keyframes shimmer {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 150% 0;
  }
}

.Sidebar_account button {
  box-shadow: none !important;
  background-color: var(--color-foreground-xlight) !important;
}

.Sidebar_account button::before {
  opacity: 0 !important;
}

.Sidebar_menu {
  display: flex;
  flex-direction: column;
  gap: var(--size-small);
  margin-left: calc(-1 * var(--sidebar-horizontal-padding));
  margin-right: calc(-1 * var(--sidebar-horizontal-padding));
}

.Sidebar_menuItem {
  display: flex;
  justify-content: flex-start;
  height: 50px;
  align-items: center;
  font-weight: var(--font-weight-bold);
  transition: opacity var(--anim-ease-out) 300ms, background-color ease 50ms;
  gap: var(--size-small);
  padding: 0 calc(var(--sidebar-horizontal-padding) / 2);
  margin: 0 calc(var(--sidebar-horizontal-padding) / 2);
  border-radius: var(--border-radius-normal);
}

.Sidebar_menuItem.selected {
  background-color: var(--color-foreground-xxlight);
}

.Sidebar_menuIcon {
  display: inline-flex;
  position: relative;
  top: -1px;
}

.Sidebar_menuIcon svg {
  display: inline-flex;
}

.Sidebar_menuIcon svg path {
  fill: var(--color-foreground);
}

.Sidebar_menuItem:active,
.Sidebar_close:active {
  opacity: 0.3 !important;
  transition-duration: 50ms;
}

.Sidebar_menuItem svg {
  pointer-events: none;
}

.Sidebar_menuItem label {
  font-size: var(--font-size-xlarge);
  pointer-events: none;
  flex: 1;
  display: flex;
  align-items: center;
}

.Sidebar_menuItemDisclosure {
  display: inline-flex;
  align-items: center;
}

.Sidebar_menuItemDisclosure svg {
  width: 20px;
  height: 20px;
}

.Sidebar_menuItemDisclosure svg path {
  fill: var(--color-foreground-medium);
}

.Sidebar_footer {
  font-size: var(--font-size-small);
  padding-bottom: var(--size-medium);
}

.Sidebar_footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  column-gap: var(--size-small);
  row-gap: var(--size-xsmall);
  white-space: nowrap;
  flex-wrap: wrap;
  color: var(--color-foreground-medium);
}

.Sidebar_footer ul li {
  display: flex;
  align-items: center;
  line-height: 1;
  height: 24px;
}

.Sidebar_footer ul li a {
  display: flex;
  align-items: center;
  color: var(--color-foreground-medium);
}

.Sidebar_footer ul li svg {
  width: 12px;
  height: auto;
}

.Sidebar_footer svg path {
  fill: var(--color-accent);
}

.Sidebar_follow {
  margin-bottom: var(--size-medium);
}

.Sidebar_follow svg path {
  fill: var(--color-foreground);
}

.Sidebar_eco {
  padding: var(--sidebar-horizontal-padding);
  margin-top: var(--size-medium);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-foreground-xxlight);
  margin-left: calc(-1 * var(--sidebar-horizontal-padding));
  margin-right: calc(-1 * var(--sidebar-horizontal-padding));
  color: var(--color-foreground-medium);
  transition: opacity ease-out 300ms;
  text-decoration: none;
  display: none;
}

.Sidebar_eco:active,
.Sidebar_eco:hover {
  opacity: var(--hover-opacity);
  color: var(--color-foreground-medium);
  transition-duration: 50ms;
}

.Sidebar_eco span {
  display: flex;
  align-items: center;
  gap: 2px;
}

.Sidebar_eco span svg {
  width: auto;
  height: 18px;
  position: relative;
  bottom: 1px;
}

.Sidebar_eco svg path,
.Sidebar_eco:active svg path {
  fill: var(--color-foreground-medium);
}

.Sidebar_profileLink,
.Sidebar_profileLink:hover {
  display: flex;
  align-items: center;
  gap: 0;
  color: var(--color-foreground-medium);
  font-weight: var(--font-weight-normal);
  transition: opacity ease-out 150ms;
}

.Sidebar_profileLink:hover {
  opacity: var(--hover-opacity);
  transition-duration: 50ms;
}

.Sidebar_profileLink svg {
  width: 8px;
}

.Sidebar_profileLink svg path {
  fill: currentColor;
}

.Sidebar_merchantToggle {
  display: flex;
  align-items: center;
  gap: var(--size-small);
  border-top: 1px solid var(--color-foreground-xlight);
  padding-top: var(--size-small);
  margin-top: var(--size-xsmall);
}

.Sidebar_merchantToggleContent {
  flex: 1;
  display: flex;
  align-items: center;
  gap: var(--size-small);
}

.Sidebar_merchantIcon {
  width: 24px;
  height: 24px;
  background-color: var(--color-accent);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  overflow: hidden;
}

.Sidebar_merchantIcon svg,
.Sidebar_merchantIcon span {
  width: 16px;
  height: 16px;
  display: inline-flex;
}

.Sidebar_merchantIcon svg path {
  fill: var(--color-background);
}

.Sidebar_merchantToggle p {
  flex: 1;
  color: var(--color-foreground-medium);
}

[data-merchant-mode="true"] .Sidebar_merchantToggle p {
  color: var(--color-foreground);
}

[data-theme="dark"] {
  .Sidebar_logo {
    opacity: 1;
  }

  .Sidebar_account button {
    background-color: var(--color-foreground-xxlight);
  }
}

@media only screen and (min-width: 992px) {
  .SidebarWrapper {
    display: grid;
    grid-template-columns: var(--sidebar-width) auto;
  }

  .SidebarWrapper.hidden-desktop {
    grid-template-columns: auto;
  }

  .SidebarWrapper.hidden-desktop .Sidebar {
    display: none;
  }

  .Sidebar {
    transform: none;
    position: relative;
    will-change: unset;
    background-color: transparent;
  }

  .SidebarShim {
    display: none;
  }

  .Sidebar_inner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--sidebar-width);
    border-right: 0;
    border-radius: 0 var(--border-radius-normal) var(--border-radius-normal) 0;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.02);
  }

  .Sidebar_menuItem,
  .Sidebar_close {
    cursor: pointer;
  }

  .Sidebar_menuItem:hover,
  .Sidebar_close:hover {
    opacity: var(--hover-opacity);
  }

  .Sidebar_menuItemDisclosure {
    display: inline-flex;
  }

  .Sidebar_eco {
    height: var(--bottom-bar-height);
    text-decoration: none;
    background-color: transparent;
    position: relative;
    opacity: 0.5;
  }

  .Sidebar_eco:before {
    position: absolute;
    left: 8px;
    top: 8px;
    bottom: 8px;
    right: 8px;
    background-color: var(--color-foreground-xxlight);
    border-radius: var(--border-radius-normal);
    content: "";
  }

  .Sidebar_eco > * {
    position: relative;
    z-index: 1;
  }

  .Sidebar_eco:hover {
    opacity: var(--hover-opacity);
  }

  .Sidebar_eco:active {
    opacity: var(--pressed-opacity);
  }

  .Sidebar_close {
    display: none;
  }

  .Sidebar_logo {
    transition: opacity var(--anim-ease-out) 300ms;
  }

  .Sidebar_logo:hover {
    opacity: var(--hover-opacity);
    transition-duration: 150ms;
  }

  .Sidebar_accountName.has-emojiname {
    cursor: pointer;
    transition: opacity ease 150ms;
  }

  .Sidebar_accountName.has-emojiname:hover {
    opacity: var(--hover-opacity);
    transition-duration: 50ms;
  }
}
