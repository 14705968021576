.ConfirmSend_Block {
  margin: var(--size-medium) 0;
  display: flex;
  flex-direction: column;
  gap: var(--size-small);
  margin-top: 0;
}

.ConfirmSend_Divider {
  height: 2px;
  background-color: var(--color-foreground-xxlight);
  width: 100%;
}

.ConfirmSend_Row {
  min-height: var(--row-min-height);
  display: flex;
  flex-direction: row;
  gap: var(--size-medium);
  align-items: center;
  padding: var(--size-small) 0;
}

.ConfirmSend_paddingContainer {
  padding: var(--size-medium);
  display: flex;
  min-height: 0;
}

.ConfirmSend_RowLeading {
  flex: 1;
  display: flex;
  color: var(--color-foreground-medium);
}

.ConfirmSend_RowTrailing {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3px;
  font-weight: var(--font-weight-bold);
}

.ConfirmSend_hasEnoughFunds {
  margin-bottom: var(--size-small);
}

.ConfirmSend_RowDetails {
  font-size: var(--font-size-small);
  color: var(--color-foreground-medium);
  font-weight: var(--font-weight-normal);
  display: flex;
  align-items: center;
}

.ConfirmSend_RowDetails button {
  font-weight: normal;
}

.ConfirmSend_UserRow {
  display: flex;
  align-items: center;
  gap: var(--size-small);
}
