.wrapper {
  min-height: var(--safe-area-height);
  overflow: hidden;
  grid-area: body;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  margin: 0 var(--size-large);
  grid-template-areas:
    "profile"
    "qr"
    "button";
  justify-content: stretch;
  row-gap: var(--size-medium);
}

.button button {
  max-width: 300px;
  width: 100%;
}

.button {
  grid-area: button;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: var(--size-medium);
}

.profile {
  display: flex;
  flex-direction: column;
  gap: var(--size-large);
  grid-area: profile;
  justify-content: center;
  padding-top: var(--size-medium);
}

.qr {
  grid-area: qr;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--size-large);
}

.qr {
  position: relative;
  top: var(--size-large);
}

.qr p {
  width: 200px;
  text-align: center;
  color: var(--color-foreground-light);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-background);
  z-index: 1;
  padding-top: var(--navbar-height);
}

.overlayNumberpad {
  height: var(--safe-area-height);
  padding: var(--size-medium);
}

.overlayActions {
  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 300px;
}

.headerActions {
  display: flex;
  align-items: center;
  gap: var(--size-medium);
}

.sendTo,
.cancel {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--font-size-small);
  color: var(--color-foreground-medium);
  line-height: 1;
  background-color: var(--color-foreground-xxlight);
  padding: 5px 10px;
  border-radius: 999em;
}

.cancel {
  appearance: none;
  border: 0;
}

.sendTo label {
  display: flex;
  gap: 4px;
  align-items: center;
}

.sendTo svg,
.cancel svg {
  width: 14px;
  height: 14px;
}

.sendTo svg path,
.cancel svg path {
  fill: var(--color-foreground-medium);
}

.drawerUserRow {
  display: flex;
  gap: var(--size-small);
  align-items: center;
}

@media only screen and (max-width: 375px) {
  .wrapper {
    gap: 0;
    grid-template-rows: min-content auto min-content;
  }

  .qr {
    top: unset;
  }

  .qr p {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "profile qr"
      "button qr";
    margin: 0;
    margin-top: calc(-1 * var(--navbar-height));
    min-height: calc(var(--safe-area-height) + var(--navbar-height) + var(--size-medium));
    gap: 0;
  }

  .profile {
    grid-area: profile;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: var(--color-background);
  }

  .button {
    display: flex;
    align-items: flex-start;
    background-color: var(--color-background);
    padding-top: var(--size-xlarge);
  }

  .qr {
    grid-area: qr;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
