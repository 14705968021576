.WelcomeSheet {
  text-align: center;
}

.WelcomeSheet img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.WelcomeSheetImage {
  margin-left: calc(-1 * var(--size-medium));
  margin-right: calc(-1 * var(--size-medium));
  margin-top: calc(-1 * var(--size-medium));
  margin-bottom: var(--size-medium);
  height: 180px;
  overflow: hidden;
  position: relative;
}

.WelcomeBody {
  display: flex;
  flex-direction: column;
  gap: var(--size-medium);
  margin: 0 var(--size-medium);
}

.WelcomeNote {
  background-color: rgba(255, 153, 0, 0.1);
  padding: var(--size-medium);
  margin-left: calc(-1 * var(--size-medium));
  margin-right: calc(-1 * var(--size-medium));
  margin-bottom: calc(-1 * var(--size-medium));
  margin-top: var(--size-medium);
}

.WelcomeNote p {
  color: rgba(255, 153, 0, 1);
}

.WelcomeLogin button {
  color: var(--color-foreground-medium);
}

.WelcomeWave {
  animation: wave ease-out 1s forwards;
  display: inline-flex;
  will-change: transform, opacity;
  animation-delay: 0.25s;
  opacity: 0;
  transform: rotate(-12deg) scale(0.5);
}

@keyframes wave {
  0% {
    transform: rotate(-12deg) scale(0.5);
    opacity: 0;
  }

  20% {
    transform: rotate(12deg) scale(1.3);
    opacity: 1;
  }

  40% {
    transform: rotate(-12deg) scale(1);
    opacity: 1;
  }

  60% {
    transform: rotate(12deg) scale(1);
    opacity: 1;
  }

  80% {
    transform: rotate(-12deg) scale(1);
    opacity: 1;
  }

  100% {
    transform: rotate(12deg) scale(1);
    opacity: 1;
  }
}
