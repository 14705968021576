.CurrencyDrawer_icon {
  width: 32px;
  height: 32px;
  background-color: var(--color-background-contrast);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.CurrencyDrawer_row {
  display: flex;
  align-items: center;
  gap: var(--size-medium);
  font-size: var(--font-size-body);
  min-width: 200px;
}

.CurrencyDrawer_row.is-selected {
  background: var(--color-accent);
  color: var(--color-lightContent);
}

.CurrencyDrawer_row.is-selected .CurrencyDrawer_icon {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: none;
}

.CurrencyDrawer_row.is-selected .CurrencyDrawer_icon svg path {
  fill: var(--color-accent);
}

.CurrencyDrawer_row svg {
  width: 24px;
  height: 24px;
}

.CurrencyDrawer_row svg path {
  fill: var(--color-foreground);
}

.CurrencyDrawer_name {
  margin: 0;
  padding: 0;
  flex: 1;
}

.CurrencyDrawer_row.is-selected svg path {
  fill: var(--color-lightContent);
}
