.NumberPad {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  gap: var(--size-large);
  align-self: stretch;
  position: relative;
  z-index: 1;
}

.NumberPad.is-disabled {
  pointer-events: none;
}

.NumberPad_amountContainer {
  width: 100%;
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.NumberPad_header {
  flex-basis: 10%;
  display: flex;
  align-items: flex-end;
}

.NumberPad-withHeader .NumberPad_amountContainer {
  flex-basis: 0;
}

.NumberPad_amount {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: var(--size-small);
  width: 100%;
  overflow: hidden;
}

.NumberPad_amountIcon {
  width: 25px;
  height: 25px;
  background-color: var(--color-foreground);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}

.NumberPad_amountIcon svg {
  width: 18px;
  height: 18px;
  display: inline-flex;
}

.NumberPad_amountIcon svg path {
  fill: var(--color-background);
}

.NumberPad_amountValue {
  font-size: var(--font-size-amount-entry);
  color: var(--color-foreground);
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: var(--font-weight-bold);
}

.NumberPad_messageContainer {
  color: var(--color-foreground-medium);
  font-size: var(--font-size-small);
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
}

.NumberPad_messageContainer > span {
  display: flex;
  gap: var(--size-xsmall);
}

.NumberPad_keypad {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  gap: var(--size-medium);
  flex: 1;
}

.NumberPad_keypadRow {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: var(--size-large);
}

.NumberPad_key {
  width: 100%;
  max-width: 72px;
  aspect-ratio: 1/1;
  appearance: none;
  border: 0;
  background-color: var(--color-foreground-xxlight);
  border-radius: 50%;
  font-size: var(--font-size-numberpad-key);
  transition: all ease-out 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-foreground);
  cursor: pointer;
  user-select: none;
}

.NumberPad_key.is-muted {
  background-color: transparent;
  color: var(--color-foreground-medium);
}

.NumberPad_key.is-hidden {
  opacity: 0;
  pointer-events: none;
  transition-duration: 30ms;
}

.NumberPad_key label {
  display: inline-flex;
  pointer-events: none;
}

.NumberPad_key svg {
  width: 24px;
  height: 24px;
  display: inline-flex;
}

.NumberPad_key svg path {
  fill: var(--color-foreground-medium);
}

.NumberPad_key:active {
  opacity: 0.3;
  transform: scale(0.9);
  transition-duration: 30ms;
}

.NumberPad_block {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

@media (max-height: 680px) {
  .NumberPad_key {
    max-width: 62px;
  }

  .NumberPad_keypadRow {
    gap: var(--size-xlarge);
  }

  .NumberPad-withHeader {
    gap: var(--size-medium);
  }
}

@media only screen and (min-width: 992px) {
  .NumberPad_amountIcon {
    width: 36px;
    height: 36px;
    bottom: 5px;
  }

  .NumberPad_amountIcon svg {
    width: 24px;
    height: 24px;
  }
}
