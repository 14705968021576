.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-medium);
}

.actions {
  color: var(--color-foreground-medium);
}

.name {
  position: relative;
  text-align: center;
  justify-items: center;
  border-radius: 999em;
  border: 1px solid var(--color-foreground-xlight);
  background: var(--color-background);
  width: fit-content;
  padding: 10px 20px;
  margin: 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
}

.link {
  background-color: var(--color-background-contrast);
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.link svg path {
  fill: var(--color-foreground-light);
  transition: fill ease 300ms;
}

.avatar {
  position: relative;
  z-index: 1;
}

.qr {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  background-color: var(--color-background);
  border-radius: calc(var(--border-radius-normal) - 2px);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

[data-theme="dark"] .qr {
  box-shadow: 0 0 0 1px var(--color-foreground-xlight);
}

.qr svg {
  display: inline-flex;
  width: 70%;
  height: auto;
}

.qr svg path {
  fill: var(--color-foreground-medium);
}

@media only screen and (min-width: 992px) {
  .photo {
    width: 120px !important;
    height: 120px !important;
  }

  .name {
    min-width: 160px;
  }

  .qr svg {
    transition: all ease 300ms;
  }

  .qr:hover svg {
    opacity: var(--hover-opacity);
    transition-duration: 100ms;
  }

  .link {
    cursor: pointer;
    transition: transform ease-out 300ms;
  }

  .link:hover svg path {
    fill: var(--color-foreground-medium);
    transition-duration: 100ms;
  }

  .link:active {
    transform: scale(0.9);
    transition-duration: 100ms;
  }
}

@media only screen and (max-width: 375px) {
  .photo {
    width: 60px !important;
    height: 60px !important;
  }
}
