.Send {
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  grid-area: body;
}

.Send_actions {
  display: flex;
  gap: var(--size-medium);
  width: 100%;
  max-width: 380px;
}

.Send_linkBox {
  display: flex;
}

.Send_linkBox p {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono",
    "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  font-weight: var(--font-weight-bold);
}

.Send_confirmWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--size-medium);
}
