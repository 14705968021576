.wrapper {
  padding: var(--size-medium);
  border-radius: var(--border-radius-normal);
  display: flex;
  flex-direction: row;
  gap: var(--size-medium);
  line-height: var(--line-height-normal);
}

.styleWarning {
  background-color: var(--color-warning-xlight);
  border: 1px solid var(--color-warning-medium);
}

.styleInfo {
  background-color: var(--color-accent-xlight);
  border: 1px solid var(--color-accent-medium);
}

.icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.icon svg {
  width: 100%;
  height: 100%;
  display: inline-flex;
}

.icon svg path {
  fill: var(--color-warning);
}
