.CurrencySelector {
  display: flex;
  gap: 4px;
  align-items: center;
}

.CurrencySelector span {
  line-height: 1;
  position: relative;
  top: 1px;
}

.CurrencySelector svg {
  width: 24px;
  height: 24px;
  opacity: 0.5;
}

.CurrencySelector svg path {
  fill: var(--color-foreground);
}

.CurrencySelector svg:last-child {
  width: 10px;
  height: auto;
}
