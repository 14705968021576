.instructions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.instructionsTitle {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-large);
  display: flex;
  gap: 3px;
  align-items: center;
  margin: 0;
}

.isExpanded {
  color: var(--color-accent);
}

.instructionsTitle svg {
  position: relative;
  top: -1px;
  transition: transform var(--anim-ease-out) 150ms;
}

.instructionsTitle svg path {
  stroke-opacity: 1;
}

.instructionsBody {
  margin-top: var(--size-small);
  border-left: 3px solid rgba(var(--color-accent-rgb), 0.2);
  padding-left: 10px;
  margin-left: 1px;
}

.fundOptions {
  margin-top: var(--size-medium);
  display: flex;
  flex-direction: column;
  gap: var(--size-medium);
}

.onrampSheetContent {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 992px) {
  .fundOptions {
    flex-direction: row;
  }
}
