.View {
  padding-bottom: var(--bottom-bar-height);
}

.View_body {
  padding: var(--size-medium);
  padding-top: 0;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.View_header {
  position: sticky;
  top: 0;
  z-index: 5;
}

@media only screen and (min-width: 992px) {
  .View_body {
    padding-top: var(--size-large);
  }
}
