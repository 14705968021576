.OnRampSheet__content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.OnRampSheet__content-grid {
  display: grid;
  grid-template-rows: [content] 1fr [footer] min-content;
}

.OnRampSheet__popup {
  display: flex;
  flex-direction: column;
  gap: var(--size-small);
  align-items: center;
  padding: 20px;
  grid-row-start: footer;
  background-color: var(--color-background);

  box-shadow: 0 -2px 14px 0 rgba(0, 0, 0, 0.03);
  border-top: 1px solid var(--color-foreground-xlight);
  border-top-left-radius: var(--border-radius-normal);
  border-top-right-radius: var(--border-radius-normal);
}

.OnRampSheet__button {
  margin: 0 auto;
}
