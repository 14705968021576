.AuthDrawer_header {
  display: flex;
  min-height: auto;
  flex-direction: column;
}

.AuthDrawer_container {
  position: relative;
  display: flex;
  gap: 16px;
  flex-direction: column;
  color: var(--color-foreground);
}

.AuthDrawer_container.AuthDrawer_loading .AuthDrawer_loader {
  display: flex;
}

.AuthDrawer_loader {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-background-medium);
  z-index: 100;
}

.AuthDrawer_icon {
  margin: 0 auto;
}
