.Header {
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: left;
  z-index: 5;
  padding-left: var(--size-medium);
  padding-right: var(--size-medium);
  gap: var(--size-medium);
  position: relative;
  background-color: var(--color-background-contrast);
}

.Header_button {
  border: 1px solid var(--color-foreground-xlight);
  color: var(--color-foreground-medium);
  font-size: var(--font-size-body);
  appearance: none;
  background: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  border-radius: 6px;
  padding: 0;
  padding-left: var(--size-small);
  padding-right: var(--size-small);
  transition: opacity var(--anim-ease-out) 300ms;
  font-weight: var(--font-weight-bold);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
  position: relative;
}

.Header_button:active {
  opacity: 0.3 !important;
  transition-duration: 50ms;
}

.Header_iconButton {
  padding: 0;
}

.Header_button svg {
  display: inline-flex;
  pointer-events: none;
}

.Header_button .Header_menu-icon {
  width: 24px;
  height: 24px;
  opacity: 0.5;
}

.Header_button .Header_menu-icon path {
  fill: var(--color-foreground);
}

.Header_button .Header__arrow-back {
  width: 16px;
  height: 16px;
}

.Header_button span {
  pointer-events: none;
}

.Header_buttonBadge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-40%) translateX(40%);
}

.Header_buttonBadge svg path {
  fill: var(--color-warning);
}

.Header_title--container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Header_logo,
.Header_title {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex: 1;
}

.Header_subtitle {
  font-size: var(--font-size-small);
  color: var(--color-foreground-medium);
  display: flex;
  align-items: center;
}

.Header_logo svg {
  width: 88px;
  height: auto;
  display: inline-flex;
  opacity: 0.5;
}

.Header_logo svg path {
  fill: var(--color-foreground);
}

.Header_title {
  font-size: var(--font-size-xlarge);
  font-weight: var(--font-weight-bold);
  color: var(--color-foreground);
}

.Header-transparent {
  --color-foreground: var(--color-lightContent);
  --color-foreground-medium: var(--color-lightContent-medium);
  --color-foreground-light: var(--color-lightContent-light);
  --color-foreground-xlight: var(--color-lightContent-xlight);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

.Header-transparent .Header_button {
  background: var(--color-foreground-xlight);
  border-color: transparent;
}

.Header-transparent .Header_logo svg {
  opacity: 1;
}

[data-theme="dark"] {
  .Header .Header_logo svg {
    opacity: 1;
  }
}

@media only screen and (min-width: 992px) {
  .Header {
    background-color: transparent;
  }

  .Header_button {
    cursor: pointer;
  }

  .Header_button:hover {
    opacity: 0.8;
    transition-duration: 50ms;
  }

  .Header_menuButton {
    display: none;
  }

  .Header_logo svg {
    display: none;
  }

  .Header.Header-isSidebarHiddenDesktop .Header_logo svg {
    display: block;
  }
}
